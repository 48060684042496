@import "colours";

.mc-form-heading {
  color:#7b8185;
  padding: 1rem;
  margin-top: 1.5rem;
}

.mc-form-heading .col {
  padding-left: 0;
}

.mc-form-heading h5 {
  font-size: 1.2rem;
  font-weight: 500;
}

.row-modifier {
  .mc-form-row-optional,
  .mc-form-row {
    background-color: #fff;
  }
}

.mc-form-row {
  padding: 15px 30px !important;
  background-color: #ebeef2;
}

.readonly-warning {
  //background-color: $mc-yellow-01;
  //color: $mc-blue-01;
  background-color: #ffc107;
  color:#212529;
}

@media only screen and (max-width: 850px) {
  .mc-form-row {
    padding: 30px !important;
  }
}

.form_msg_block {
  display: flex;
  align-items: center;
  gap: 1rem;
  background-color: #ffc107;
  color: #212529;
  margin: 1rem 0;
  padding: 1rem;

  p {
    margin: 0;
    padding: 0;
  }
}