@import "colours";
@import "variables";
@import "typography";

.input-fields {
  background-color: $mc-white-01;
  width: 100%;
  border: none;
  font-family: $body-font;
  min-width: 195px !important;
  padding: 9px 15px;
  margin-top: 0.25rem;
}

.text-area-inputs {
  min-height: 200px;
}
