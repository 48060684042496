$c1: #FFFFFF;
$c1-content: #272323;
$c1-hover: #F6F6F6;
$c1-focus: #EBEBEB;
$c2: #1A2A35;
$c2-content: #FFFFFF;
$c2-hover: #346C93;
$c2-focus: #2A5A7B;
$c3: #858C71;
$c4: #D9D9D9;
$c10: #1A2A35;
$c10-content: #FFFFFF;
$c10-hover: #2A5A7B;

$mc-blue-01: #1a2a35;
$mc-blue-02: #254f64;
$mc-blue-03: darken($mc-blue-01, 2%);
$mc-yellow-01: #d0d700;
$mc-grey-01: #c9c9c9;
$mc-grey-02: #e7e7e7;
$mc-grey-03: #777777;
$mc-grey-04: #cccccc;
$mc-grey-05: #dddddd;
$mc-grey-06: #7e898d;
$mc-grey-07: darken($mc-grey-06, 10%);

$mc-blue-04: #2fb6d0;
$mc-blue-05: #65d4eb;
$mc-red-01: #e2403b;
$mc-red-02: #f57571;
$mc-orange-01: #d2823e;
$mc-orange-02: #edb07b;
$mc-green-01: #41bd6a;
$mc-green-02: #6cd991;

$mc-yellow-02: #c3ac36;
$mc-yellow-03: #e0cc65;

$mc-black-01: #1f1f1f;
$mc-white-01: #ffffff;

$mc-yellow-hover: darken($mc-yellow-01, 10%);
$mc-dark-blue-hover: darken($mc-blue-01, 10%);
$mc-white-hover: darken($mc-white-01, 20%);
$mc-light-blue-hover: darken($mc-blue-04, 10%);
$mc-red-hover: darken($mc-red-01, 10%);
$mc-orange-hover: darken($mc-orange-01, 10%);
$mc-green-hover: darken($mc-green-01, 10%);

$mc-yellow-disabled: desaturate($mc-yellow-01, 30%);
$mc-dark-blue-disabled: desaturate($mc-blue-01, 30%);
$mc-light-blue-disabled: desaturate($mc-blue-04, 30%);
$mc-red-disabled: desaturate($mc-red-01, 30%);
$mc-orange-disabled: desaturate($mc-orange-01, 30%);
$mc-green-disabled: desaturate($mc-green-01, 30%);

$mc-dark-blue-transparent: rgba(21,43,54,.95);
$mc-blue-02-lighter: lighten($mc-blue-02, 30%);
$mc-yellow-transparent: rgba(208,215,0,.65);

$vibrant-green: #41bd6a;
$vibrant-green-sec: #6cd991;
$vibrant-red: #e2403b;
$vibrant-red-sec: #f57571;
$vibrant-orange: #d2823e;
$vibrant-orange-sec: #edb07b;
$vibrant-yellow: #c3ac36;
$vibrant-yellow-sec: #e0cc65;
$vibrant-blue: #2fb6d0;
$vibrant-blue-sec: #65d4eb;
