@import "colours";
@import "typography";
@import "variables";

.mobile-list-wrapper {
  position: relative;
  transition: max-height 0.5s ease;
  max-height: 1000px;
  transform-origin: top;
  overflow-x: hidden;
  overflow-y: scroll;
  width: 100%;
  cursor: pointer;
  font-size: 18px;
}

.mobile-list-item-wrapper {
  display: flex;
  width: 100%;
  transition: transform $animation-duration;

  .mobile-list-item {
    flex: 1 0 100%;
  }
}

.mobile-list-item {
  width: 100%;
  display: flex;
  border-radius: 2px;
  border: 1px solid $mc-grey-04;

  margin: 5px 0;
  padding: 15px 8px;
  box-shadow: $mc-grey-05 0 0 5px 5px;
}

.mobile-list-item-left-wrapper {
  width: 25%;
  max-width: 80px;
  border-right: 3px solid $mc-blue-02;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 5px;
}

.mobile-view-initials-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.mobile-view-initials {
  background-color: $mc-blue-02;
  padding: 15px;
  font-family: $header-font;
  color: $mc-white-01;
  border-radius: 50%;
  aspect-ratio: 1 / 1;
  width: auto;
}

.mobile-list-item-details {
  flex-grow: 8;
  padding: 0 5px 0 10px;
}

.mobile-list-item-detail-header {
  font-family: $header-font;
  font-size: 16px;
}

.mobile-list-item-detail-body {
  font-family: $body-font;
  color: $mc-blue-03;
  font-size: 14px;
}

.mobile-list-item-detail-additional-info {
  font-size: 14px;
}

.delete-button {
  display: flex;
  flex-direction: column;
  background-color: $mc-red-01;
  border: none;
  margin: 5px;
  color: $mc-white-01;
  font-family: $header-font;
  align-items: center;
  justify-content: center;
  width: auto;
  padding: 0 10px;
  border-radius: 2px;
  transition: background-color ease-in-out $animation-duration;

  &:disabled {
    background: $mc-grey-03;
  }
}
