div.navbar_wrapper {
    --block-img-width: 18px;
    --block-text-size: 0.8rem;
    --block-img-spacing: 0.5rem;

    --nav-collapsed-width: 6.5rem;
    --nav-expanded-width: 17rem;
    --nav-transition-speed: 150ms;

    --screen-lg: 1024px;

    --color-c4: 217 217 217;
    --color-c10: 26 42 53;
    --color-c10-content: 255 255 255;
    --color-c10-hover: 42 90 123;

    width: var(--nav-collapsed-width);
    min-width: var(--nav-collapsed-width);
    transition-property: background-color;
    transition-duration: var(--nav-transition-speed);
    position: relative;
    z-index: 100;
    color: rgb(var(--color-c10-content));

    @media (prefers-reduced-motion) {
        transition: none;
    }

    nav.navbar {
        transition-property: width, background-color;
        transition-duration: var(--nav-transition-speed);
        position: fixed;
        overflow-x: clip;
        overflow-y: auto;
        z-index: 100;
        top: 0;
        width: var(--nav-collapsed-width);
        height: 100%;
        left: -100%;
        scrollbar-color: rgba(42, 90, 123, 0.8) transparent;
        scrollbar-width: thin;        
        background-color: rgb(var(--color-c10));
        backdrop-filter: blur(4px);
        padding-bottom: 1rem;
        display: flex;
        flex-direction: column;
        gap: 0;
        justify-content: start;

        @media (min-width: 1024px) {
            left: 0;
        }

        @media (prefers-reduced-motion) {
            transition: none;
        }

        .heading {
            font-size: 0.875rem;
            line-height: 1.25rem;
            color: rgba(var(--color-c10-content) / 0.6);
            text-align: center;
        }

        .link_icon {
            display: flex;
            gap: 0.5rem;
            align-items: center;
        }

        a {
            font-weight: inherit;
            color: rgba(var(--color-c10-content) / 1);
            display: block;
        }

        .logo_block {
            justify-content: flex-end;
            display: flex;
            align-items: center;
            height: 3.5rem;
            margin-bottom: 1rem;
            padding-top: 1rem;

            @media (min-width: 1024px) {
                height: 5rem;
            }
        }

        .logo_block_inner {
            justify-content: center;
            flex-grow: 1;
            display: flex;
            align-items: center;
            color: rgb(var(--color-c10-content));
        }

        .logo_big, .logo_small {
            height: 100%;
            width: 100%;
            padding-left: 1rem;
            padding-right: 1rem;
            transition-property: opacity;
            transition-duration: var(--nav-transition-speed);
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;

            @media (prefers-reduced-motion) {
                transition: none;
            }
        }

        .logo_big {
            opacity: 0;
            max-width: 100%;
            position: relative;
        }

        .logo_small {
            opacity: 1;
            max-height: 35px;
            max-width: var(--nav-collapsed-width);
            position: absolute;
            left: 0;
        }

        .close {
            transition-property: opacity;
            transition-duration: var(--nav-transition-speed);
            opacity: 0;
            padding: 0 0.75rem 0 0;
            margin: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            background: none;
            border: none;
            color: rgb(var(--color-c10-content));

            @media (prefers-reduced-motion) {
                transition: none;
            }
        }

        .section {
            width: var(--nav-collapsed-width);
            display: flex;
            flex-direction: column;
            margin-bottom: 1.5rem;
            gap: 0.5rem;

            &.section_mobile {
                @media (min-width: 1024px) {
                    display: none;
                }
            }
        }

        .section_children {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
        }

        .block {
            --block-padding-x: 1rem;
            --block-padding-y: 0.5rem;
            position: relative;
            min-height: calc((var(--block-text-size) * 1.5) + (var(--block-padding-y) * 2));
            font-size: var(--block-text-size);
            width: var(--nav-expanded-width);
            padding: 0 var(--block-padding-x) 0 var(--block-padding-x);

            a {
                color: rgba(var(--color-c10-content) / 1);
            }

            .highlight {
                position: absolute;
                /*width: calc((var(--block-padding-x) * 2) + var(--block-img-width) + 1rem + var(--block-img-spacing));
                left: calc((var(--nav-collapsed-width) / 2) - (((var(--block-padding-x) * 2) + var(--block-img-width) + 1rem + var(--block-img-spacing)) / 2));*/
                left: calc((var(--nav-collapsed-width) / 2) - (72px / 2));
                width: 72px;
                top: 0;
                height: 100%;
                border-radius: 0.5rem;
                background-color: rgb(var(--color-c10-hover));
            }

            .content {
                padding: var(--block-padding-y) 0 var(--block-padding-y) 0;
                border-radius: 0.5rem;
                display: flex;
                flex-direction: row;
                align-items: center;
                min-height: calc((var(--block-text-size) * 1.5) + (var(--block-padding-y) * 2));
                height: 100%;
                transition-property: background-color;
                transition-duration: var(--nav-transition-speed);

                @media (prefers-reduced-motion) {
                    transition: none;
                }

                .block_img {
                    width: var(--block-img-width);
                    height: var(--block-img-width);
                    position: absolute;
                    top: calc(((var(--block-text-size) * 1.5) / 2) - (var(--block-img-width) / 2) + (var(--block-padding-y)));
                    left: calc((var(--nav-collapsed-width) / 2) - (var(--block-img-width) / 2));
                    color: rgba(var(--color-c10-content) / 0.6);
                }

                .block_head {
                    transition-property: opacity;
                    transition-duration: var(--nav-transition-speed);
                    opacity: 0;
                    position: absolute;
                    left: calc((var(--nav-collapsed-width) / 2) + (var(--block-img-width) / 2) + var(--block-img-spacing));
                    top: calc(var(--block-padding-y));
                    width: calc(var(--nav-expanded-width) - ((var(--nav-collapsed-width) / 2) + (var(--block-img-width) / 2) + var(--block-img-spacing) + (var(--block-padding-x) * 2)));
                }

                .extended_content {
                    transition-property: opacity;
                    transition-duration: var(--nav-transition-speed);
                    opacity: 0;
                    padding: calc((var(--block-text-size) * 1.5) + 0.25rem) var(--block-padding-x) 0 calc((var(--nav-collapsed-width) / 2) + (var(--block-img-width) / 2) + var(--block-img-spacing) - var(--block-padding-x));
                }
            }
        }

        &.show {
            background-color: rgba(var(--color-c10) / 0.9);
            width: var(--nav-expanded-width);
            left: 0;

            .logo_big {
                opacity: 1;
            }

            .logo_small {
                opacity: 0;
            }

            .close {
                opacity: 1;
            }

            .block {
                .block_head, .extended_content {
                    opacity: 1;
                }

                &.active {
                    .content {
                        background-color: rgb(var(--color-c10-hover));
                    }
                }

                &:hover {
                    cursor: pointer;

                    .content {
                        background-color: rgb(var(--color-c10-hover));
                    }

                    .block_img {
                        color: rgb(var(--color-c10-content));
                    }
                }
            }
        }
    }
}
